.dc-chart{
  .axis{
    .grid-line,
    .grid-line line{
      stroke: $gray-light;
    }
  }

}

// .dc-chart .axis line,
// .dc-chart .axis path{
//   stroke: $gray-lighter;
// }
