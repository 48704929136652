#gmaps,
#gmaps-loading,
gmaps {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
}

#gmaps-loading {
    pointer-events: none;
    background: rgba(0,0,0,0.5);
    color: white;
}

#map-data-tooltip {
    padding: $grid-gutter-width;
    max-width: 500px;
    background: rgba(0,0,0,0.75);
    color: $gray-lighter;
    position: fixed;
    z-index: 2000;
    max-height: 500px;
    overflow-y: auto;
}
