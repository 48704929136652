/*
# Urbane 2015.
# Pluto data viewer
*/

html, body, #gmaps {
    margin: 0;
    padding: 0;
    min-height: 100%;
    /*height: 100%;
    width: 100%;*/
    /*font-size: 10px;*/
}

.bgStyle{
   padding: 5px;
   background-color: rgba(255,255,255,0.5);
   border-radius: 5px;
   border: 1px solid #bfbfbf;
}

#mainMenu{
    position: fixed;
    top: 5px;
    left:5px;
    z-index: 10;
}

#renderMenu{
    position: fixed;
    top: 5px;
    right:5px;
    z-index: 10;
}


#cards{
    position: fixed;
    top: 50px;
    left:5px;
}

.card{
    width: 400px;
    height: 250px;
    margin-bottom: 5px;
}

.leftSpace{
    margin-left: 5px;
}

#plutoSelector, #plutoSelectorCompare,#renderFunctionSelector, #propSelector, #cscale{
    display: inline-block;
    vertical-align: top;
}

#closeCard{
    float:right;
}

.x.axis text {
    text-anchor: end !important;
    transform: rotate(-45deg);
}
