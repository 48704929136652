@import "variables";
@import "./lib/themes/paper/variables";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "./lib/themes/paper/bootswatch";
@import "./lib/dc.min";
@import "./lib/histogram";

@import "./index";

@import "custom-scrollbar";
@import "util";
@import "gmaps";
@import "dc-gen";
@import "dc-row";

#main {
    // padding-top: $navbar-height + $grid-gutter-width;
    padding-top: $grid-gutter-width;

    .left-sidebar{
      max-width: 500px;
    }
}
